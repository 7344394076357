import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import _getFactors2 from "./getFactors";
import _getDistances2 from "./getDistances";
import _advanceWidth2 from "../../attributedString/advanceWidth";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var _getFactors = _interopRequireDefault(_getFactors2);

var _getDistances = _interopRequireDefault(_getDistances2);

var _advanceWidth = _interopRequireDefault(_advanceWidth2);

function _createForOfIteratorHelperLoose(o, allowArrayLike) {
  var it;

  if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) {
    if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") {
      if (it) o = it;
      var i = 0;
      return function () {
        if (i >= o.length) return {
          done: true
        };
        return {
          done: false,
          value: o[i++]
        };
      };
    }

    throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }

  it = o[Symbol.iterator]();
  return it.next.bind(it);
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }

  return arr2;
}
/**
 * Adjust run positions by given distances
 *
 * @param {Array} distances
 * @param {Object} line
 * @returns {Object} line
 */


var justifyLine = function justifyLine(distances, line) {
  var index = 0;

  for (var _iterator = _createForOfIteratorHelperLoose(line.runs), _step; !(_step = _iterator()).done;) {
    var run = _step.value;

    for (var _iterator2 = _createForOfIteratorHelperLoose(run.positions), _step2; !(_step2 = _iterator2()).done;) {
      var position = _step2.value;
      position.xAdvance += distances[index++];
    }
  }

  return line;
};
/**
 * A JustificationEngine is used by a Typesetter to perform line fragment
 * justification. This implementation is based on a description of Apple's
 * justification algorithm from a PDF in the Apple Font Tools package.
 *
 * //TODO: Make it immutable
 *
 * @param {Object} layout options
 * @param {Object} line
 * @returns {Object} line
 */


var justification = function justification(options, line) {
  var gap = line.box.width - (0, _advanceWidth.default)(line);
  if (gap === 0) return; // Exact fit

  var factors = (0, _getFactors.default)(gap, line, options);
  var distances = (0, _getDistances.default)(gap, factors);
  return justifyLine(distances, line);
};

var _default = R.curryN(2, justification);

exports.default = _default;
export default exports;
export const __esModule = exports.__esModule;